export function validateCpfCnpj(input) {
  const cleanInput = input.replace(/[^0-9]/g, "")
  if (cleanInput.length === 11) {
    return validateCpf(cleanInput)
  } else if (cleanInput.length === 14) {
    return validateCnpj(cleanInput)
  } else {
    return false
  }
}

function validateCpf(cpf) {
  // Remove todos os caracteres não numéricos
  cpf = cpf.replace(/\D/g, "")

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false
  }

  // Verifica se todos os dígitos são iguais, o que é inválido
  if (/^(\d)\1+$/.test(cpf)) {
    return false
  }

  // Calcula o primeiro dígito verificador para comparar com o CPF informado
  let sum = 0
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i)
  }
  let remainder = 11 - (sum % 11)
  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }
  if (remainder !== parseInt(cpf.charAt(9))) {
    return false
  }

  // Calcula o segundo dígito verificador
  sum = 0
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i)
  }
  remainder = 11 - (sum % 11)
  if (remainder === 10 || remainder === 11) {
    remainder = 0
  }
  if (remainder !== parseInt(cpf.charAt(10))) {
    return false
  }

  // CPF válido
  return true
}

export function validateCnpj(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "")

  if (cnpj === "") return false

  if (cnpj.length !== 14) return false

  if (/^(\d)\1+$/.test(cnpj)) return false

  let size = cnpj.length - 2
  let numbers = cnpj.substring(0, size)
  const digits = cnpj.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * pos--
    if (pos < 2) pos = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(digits.charAt(0), 10)) return false

  size += 1
  numbers = cnpj.substring(0, size)
  sum = 0
  pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * pos--
    if (pos < 2) pos = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(digits.charAt(1), 10)) return false

  return true
}
