import { useQuery } from "react-query"
import { api } from "../services/api"

async function getListaVerbaCliente() {
  const response = await api.get("/chamado/listar-tipo-verbas")

  return response.data.map((item) => {
    return {
      value: item.id,
      label: item.valor,
    }
  })
}

export const useVerbaCliente = () => {
  const { data: verbaCliente = [], isLoading } = useQuery({
    queryKey: "verbaCliente",
    queryFn: getListaVerbaCliente,
    refetchOnWindowFocus: false,
    staleTime: 60 * 1, // 1 minuto
  })

  return {
    verbaCliente,
    isLoading,
  }
}
